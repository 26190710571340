import {
    SET_DEAL_VIEW
} from "../../constants/ActionTypes";

const INIT_STATE = {
    dealView: JSON.parse(localStorage.getItem('deal_view')),
};


export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case SET_DEAL_VIEW: {
            return {
                ...state,
                dealView: action.payload,
            };
        }

        default:
            return state;
    }
}
