import {SET_TASK_VIEW} from "../../constants/ActionTypes";


export const setTaskView = (value)=>{
   
    return {
        type: SET_TASK_VIEW,
        payload:value
    }
    
}