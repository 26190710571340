import React, {useEffect, useState} from "react";
import {Avatar, Divider, Drawer, Input, Pagination, Popconfirm, Spin, message, Button} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {closeCompanyDrawer, fetchRecords, getActiveCompany, getUser, saveRecords} from "../../appRedux/actions";
import {CloseOutlined} from "@ant-design/icons";
import {DOCUMENT_BASE_URL, SEARCH_COMPANY_QUERIES, USERS_COMPANIES_URL, USERS_URL} from "../../constants/ServerUrl";

const CompanyDrawer = () => {
    const {companyDrawer, activeCompany} = useSelector(({company}) => company);
    const {authUser} = useSelector(({auth}) => auth);
    const dispatch = useDispatch();
    const [mount, setMount] = useState(true);
    const [loading, isLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 20, total: 0});
    const [query, setQuery] = useState("");
    const {Search} = Input;
    const onClose = () => {
        dispatch(closeCompanyDrawer());
    }

    useEffect(() => {
        if (mount) {
            if (authUser) {

                getUserCompanies();
            }
        }
        return () => {
            setMount(false);
        }
    }, [authUser]);
    const getUserCompanies = (paging = pagination, searchParam = query) => {
        if (loading) {
            return;
        }
        isLoading(true);
        //fetchRecords(USERS_COMPANIES_URL + "?user_id=" + authUser.id + "&sort_by=companies.business_name&direction=asc&per_page=" + paging.pageSize + "&page=" + paging.current + "&query=" + searchParam).then((data) => {
        fetchRecords(USERS_COMPANIES_URL + "?sort_by=companies.business_name&direction=asc&per_page=" + paging.pageSize + "&page=" + paging.current + "&query=" + searchParam).then((data) => {
            if (data.success) {
                setCompanies(data.data.data);
                setPagination({
                    current: data.data.paginate.current_page,
                    pageSize: data.data.paginate.per_page,
                    total: data.data.paginate.total
                });
            }
            isLoading(false);
        }).catch(err => {
            isLoading(false);
            console.log(err);
        });
    }

    const onPageChange = (page = pagination.current) => {

        getUserCompanies({pageSize: pagination.pageSize, current: page});
    }

    const switchActiveCompany = (id) => {
        isLoading(true);
        let formData = new FormData();
        formData.append('active_company', id);
        formData.append('r_type', '2');
        formData.append('_method', 'put');
        saveRecords(USERS_URL + "/" + authUser.id, formData).then((data) => {
            if (data.success) {
                dispatch(getActiveCompany());
                onPageChange();
                onClose();
            }
        }).catch(err => {
            console.log(err);
        });
    }
    const onSearch = (e) => {
        let value = e.target.value;

        if (value.length === 0) {
            setQuery("");
            setPagination({current: 1, pageSize: 20, total: 0});
            getUserCompanies({current: 1, pageSize: 20, total: 0}, "");
        }

        if (value.length < 3) {

            return;
        }
        setQuery(value);
        getUserCompanies(pagination, value);

    }

    return (
        <Drawer
            title=""
            placement="right"
            closable={true}
            onClose={onClose}
            visible={companyDrawer}
            key="right"
            width={362}
            mask={true}
            closeIcon={
                <div className="bg-white rounded-full px-1 pb-4 h-6 text-black"><CloseOutlined/></div>
            }
            drawerStyle={{padding: "unset"}}
        >

            {activeCompany && (
                <>
                    <div className="bg-gray-300 h-24">
                        {!activeCompany.company_logo_path && (
                            <div className="flex items-center justify-center  ">
                                <Avatar className="w-24 h-24 top-14"
                                        src="/images/app-icons/placeholder.jpg"
                                />

                            </div>)}
                        {activeCompany.company_logo_path && (
                            <div className="flex items-center justify-center  ">
                                <Avatar className="w-24 h-24 top-14"
                                        src={`${DOCUMENT_BASE_URL}/${activeCompany.company_logo_path}`}
                                />

                            </div>)}
                    </div>
                    <div className="relative mt-16 text-center">
                        <p className="font-semibold text-xl mb-2">{activeCompany.business_name}</p>
                        <p>{activeCompany.business_code}</p>
                    </div>
                </>
            )}
            {!activeCompany && (
                <>
                    <div className="bg-gray-300 h-24">

                        <div className="flex items-center justify-center  ">
                            <Avatar className="w-24 h-24 top-14"
                                    src="/images/app-icons/placeholder.jpg"/>

                        </div>
                    </div>
                    <div className="relative mt-16 text-center">
                        <p className="font-semibold text-xl mb-2">No Active Company for this account</p>
                        <p>Please select a company below</p>
                    </div>
                </>
            )}
            <div>
                <Divider/>
                {companies.length <= 0 && !activeCompany && (
                    <div className="text-center">
                        <p className="font-lg">No Company</p>
                        <p className="font-sm">You have not been assigned any company, please contact the
                            administrator</p>

                    </div>
                )}
                {companies.length <= 0 && activeCompany && (
                    <div className="text-center">
                        <p className="font-lg">You are currently on an Active Company</p>
                        <p className="font-sm">You have no other company to select from</p>
                        <Button onClick={() => {
                            setQuery("");
                            setPagination({current: 1, pageSize: 20, total: 0});
                            getUserCompanies({current: 1, pageSize: 20, total: 0}, "");
                        }}>Refresh</Button>
                    </div>
                )}

                {companies.length > 0 && (
                    <div>
                        <div className="-mt-6 pb-10">

                            <Search placeholder="Search Company" autoFocus={false} onChange={onSearch}/>
                        </div>
                        <Spin spinning={loading}>
                            {companies.map((company, index) => {
                                return (
                                    <div className="hover:bg-gray-200 px-8 pt-4" key={index}>
                                        <Popconfirm title="Change active company to the selected?"
                                                    okText="Yes"
                                                    cancelText="No"
                                                    placement="leftTop"
                                                    onConfirm={() => switchActiveCompany(company.company_id)}>
                                            <div className="flex  cursor-pointer">
                                                {company.company_logo_path && (
                                                    <Avatar className="w-10 h-10 "
                                                            src={`${DOCUMENT_BASE_URL}/${company.company_logo_path}`}/>
                                                )}
                                                {!company.company_logo_path && (
                                                    <Avatar className="w-10 h-10 "
                                                            src="/images/app-icons/placeholder.jpg"/>
                                                )}
                                                <p className="text-sm py-2 px-5 font-semibold">{company.business_name}</p>
                                            </div>
                                        </Popconfirm>
                                        <Divider/>
                                    </div>
                                );
                            })}
                        </Spin>
                    </div>
                )}


                <Pagination size="small" defaultCurrent={pagination.current} total={pagination.total}
                            onChange={onPageChange}
                            pageSize={pagination.pageSize}/>


            </div>
        </Drawer>
    );
}

export default CompanyDrawer;