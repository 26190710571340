import {
    SET_TASK_VIEW
} from "../../constants/ActionTypes";

const INIT_STATE = {
    taskView: JSON.parse(localStorage.getItem('task_view')),
};


export default (state = INIT_STATE, action) => {

    switch (action.type) {

        case SET_TASK_VIEW: {
            return {
                ...state,
                taskView: action.payload,
            };
        }

        default:
            return state;
    }
}
