import {SET_DEAL_VIEW} from "../../constants/ActionTypes";


export const setDealView = (value)=>{
   
    return {
        type: SET_DEAL_VIEW,
        payload:value
    }
    
}