import {
    ACTIVE_COMPANY, BUSINESS_IDENTITY, BUSINESS_INFORMATION,
    CLOSE_COMPANY_DRAWER, CONTACT_PERSONS,

    OPEN_COMPANY_DRAWER, RESET_COMPANY,

} from "../../constants/ActionTypes";
import axios from "../../util/Api";
import {ACTIVE_COMPANY_URL} from "../../constants/ServerUrl";

export const openCompanyDrawer = () => {
    return {
        type: OPEN_COMPANY_DRAWER
    }
};

export const closeCompanyDrawer = () => {
    return {
        type: CLOSE_COMPANY_DRAWER
    }
};


export const setBusinessInformation = (data) => {
    return {
        type: BUSINESS_INFORMATION,
        payload: data
    };
}

export const setContactPersons = (data) => {
    return {
        type: CONTACT_PERSONS,
        payload: data
    };
}

export const setBusinessIdentity = (data) => {
    return {
        type: BUSINESS_IDENTITY,
        payload: data
    };
}

export const resetCompanyInformation = () => {
    return {
        type: RESET_COMPANY,
    };
}

export const getActiveCompany = (location = "/") => {
    // console.log();
    let token = JSON.parse(localStorage.getItem("token"));
    //  axios.defaults.headers.common['Accept'] = " application/json";
    if (token) {

        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    }

    return (dispatch) => {
        //dispatch({type: FETCH_START});
        axios.get(ACTIVE_COMPANY_URL).then(({data}) => {
            if (data.success) {

                //dispatch({type: FETCH_SUCCESS});
                dispatch({type: ACTIVE_COMPANY, payload: data.data});

            }
        }).catch(function (error) {
            //  dispatch({type: FETCH_ERROR, payload: error.message});

            console.log("Error****:", error.message);
        });
    }
};






