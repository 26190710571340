// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const SET_CURRENT = 'SET_CURRENT';
export const SET_CURRENT_DATE = 'SET_CURRENT_DATE';

//Auth const

export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const EXPIRES_SIGNATURE = 'expires_signature';
export const SET_APP_AUTHORIZATION = 'SET_APP_AUTHORIZATION';
export const SET_IDLE_ID = 'SET_IDLE_ID';
export const SET_WARNING_ID = 'SET_WARNING_ID';

//Company const
export const OPEN_COMPANY_DRAWER = 'OPEN_COMPANY_DRAWER';
export const CLOSE_COMPANY_DRAWER = 'CLOSE_COMPANY_DRAWER';
export const ACTIVE_COMPANY = 'ACTIVE_COMPANY';

export const BUSINESS_INFORMATION = 'business_information';
export const CONTACT_PERSONS = 'contact_persons';
export const BUSINESS_IDENTITY = 'business_identity';
export const RESET_COMPANY = 'reset_company';
//Employee const
export const PERSONAL_INFORMATION = 'PERSONAL_INFORMATION';
export const HIRING_INFORMATION = 'HIRING_INFORMATION';
export const BANK_INFORMATION = 'BANK_INFORMATION';
export const RESET_EMPLOYEE = 'RESET_EMPLOYEE';
export const VARIATION_FILE = 'VARIATION_FILE';
export const VARIATION_INFORMATION = 'VARIATION_INFORMATION';
export const SELECTED_EMPLOYEE = 'SELECTED_EMPLOYEE';
export const SELECTED_TASKS = 'SELECTED_TASKS';
export const SELECTED_DOWNLOADS = 'SELECTED_DOWNLOADS';
export const SELECTED_UPLOADS = 'SELECTED_UPLOADS';
export const RESET_ONBOARDING = 'RESET_ONBOARDING';
export const EMPLOYEE_RELATIONSHIPS = 'EMPLOYEE_RELATIONSHIPS';

//Candidate const
export const BASIC_INFORMATION = 'BASIC_INFORMATION';
export const ONBOARDING_INFORMATION = 'ONBOARDING_INFORMATION';
export const SELECTED_CANDIDATE = 'SELECTED_CANDIDATE';
export const RESET_CANDIDATE = 'RESET_CANDIDATE';
export const SAVE_COUNTRIES = 'SAVE_COUNTRIES';


//Payroll const
export const PAYROLL_INFORMATION = 'PAYROLL_INFORMATION';
export const PAYROLL_PERIOD = 'PAYROLL_PERIOD';
export const PENDING_PAYMENT = 'PENDING_PAYMENT';
export const PAYROLL_ITEMS = 'PAYROLL_ITEMS';
export const RESET_PAYROLL = 'RESET_PAYROLL';

//crm management

export const SET_DEAL_VIEW = "SET_DEAL_VIEW"
export const SET_TASK_VIEW = "SET_TASK_VIEW"