import React, { useState, useRef, useEffect } from 'react';
import { Input, Spin, List, Avatar, Typography } from 'antd';
import { UserOutlined, FileTextOutlined, ShopOutlined, DollarOutlined, PhoneOutlined, ScheduleOutlined } from '@ant-design/icons';
import { fetchRecords } from "../../appRedux/actions";
import { CRM_GENERAL_SEARCH } from "../../constants/ServerUrl";
import { Link } from 'react-router-dom'; // Import Link
import Helpers from '../../util/Helpers';

const { Search } = Input;
const { Title } = Typography;

const GeneralSearch = () => {
    const [results, setResults] = useState({});
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const searchRef = useRef(null);

    const handleSearch = async (query) => {
        setSearchQuery(query);
        setLoading(true);
        try {
            const data = await fetchRecords(`${CRM_GENERAL_SEARCH}?query=${query}`);
            setResults(data.data || {});
            setShowDropdown(true);
        } catch (error) {
            console.error('Error fetching search results:', error);
        } finally {
            setLoading(false);
        }
    };

    const highlightText = (text) => {
        if (!searchQuery) return text;
        const regex = new RegExp(`(${searchQuery})`, 'gi');
        return text.replace(regex, match => `<span style="background-color: yellow">${match}</span>`);
    };

    const getIcon = (category) => {
        switch (category) {
            case 'clients': return <ShopOutlined />;
            case 'deals': return <DollarOutlined />;
            case 'leads': case 'contacts': return <UserOutlined />;
            case 'bids': return <FileTextOutlined />;
            case 'notes': return <FileTextOutlined />;
            case 'tasks': return <ScheduleOutlined />;
            default: return <FileTextOutlined />;
        }
    };

    const getTitle = (item, category) => {
        switch (category) {
            case 'clients': return item.business_name;
            case 'deals': return item.deal_name;
            case 'leads': return item.lead_name;
            case 'bids': return item.bid_title;
            case 'contacts': return item.contact_name;
            case 'notes': return `Note ID: ${item.id}`;
            case 'tasks': return item.task_name;
            default: return 'Unknown';
        }
    };

    const getDescription = (item, category) => {
        switch (category) {
            case 'clients': return `Industry ID: ${item.industry_id}`;
            case 'deals': return `Amount: ${Helpers.formatCurrency(item.amount, item.currency)}`;
            case 'leads': return item.email;
            case 'bids': return `Bid Amount: ${Helpers.formatCurrency(item.bid_amount, item.currency)}`;
            case 'contacts': return item.contact_email;
            case 'notes':   return `<p>${item.description.substring(0, 500)}...</p>`;
            case 'tasks': return item.task_description.substring(0, 50) + '...';
            default: return '';
        }
    };

    const getRoute = (category, id) => {
        switch (category) {
            case 'clients':
                return `/client-details/${id}`;
            case 'leads':
                return `/lead-details/${id}`;
            case 'deals':
                return `/deals?id=${id}` // Assuming you have a deal details page
            case 'contacts':
                return `/contacts?id=${id}` // Assuming you have a contact details page
            case 'notes':
                return `/note-details/${id}`; // Assuming you have a note details page
            case 'tasks':
                return `/tasks?id=${id}`// Assuming you have a task details page
            case 'bids':
                return `/bids?id=${id}`// Assuming you have a bid details page
            // Add more cases as needed for other categories
            default:
                return '/'; // Fallback route
        }
    };

    const renderResults = () => {
        const categoriesWithResults = [];
        const categoriesWithoutResults = [];

        Object.entries(results).forEach(([category, items]) => {
            if (items.length > 0) {
                categoriesWithResults.push([category, items]);
            } else {
                categoriesWithoutResults.push(category);
            }
        });

        return (
            <>
                {categoriesWithResults.map(([category, items]) => (
                    <div key={category} className='border-b-2 border-gray-100'>
                        <p className="mt-0 mb-0 px-4 text-medium font-bold mt-2 text-leading" style={{ lineHeight: '1.8rem' }}>
                            {category.charAt(0).toUpperCase() + category.slice(1)}
                        </p>
                        <List
                            itemLayout="horizontal"
                            dataSource={items}
                            className='ml-4'
                            renderItem={item => (
                                <List.Item className='mt-0'>
                                    <List.Item.Meta
                                        avatar={<Avatar icon={getIcon(category)} />}
                                        title={
                                            <Link to={getRoute(category, item.id)} dangerouslySetInnerHTML={{ __html: highlightText(getTitle(item, category)) }} />
                                        }
                                        description={<div dangerouslySetInnerHTML={{ __html: highlightText(getDescription(item, category)) }} />}
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                ))}
                {/* {categoriesWithoutResults.map(category => (
                    <div key={category} className='border-b-2 border-gray-100'>
                        <p className="mt-0 mb-0 px-4 text-medium font-bold mt-2 text-leading" style={{ lineHeight: '1.8rem' }}>
                            {category.charAt(0).toUpperCase() + category.slice(1)}
                        </p>
                        <p className="ml-4 text-gray-500">No result to show</p>
                    </div>
                ))} */}
            </>
        );
    };

    // Add useEffect to handle clicks outside the search component
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchRef]);

    return (
        <div className="p-4 relative" ref={searchRef}>
            <div className="flex justify-center mb-4">
                <Search
                    placeholder="Search..."
                    onSearch={handleSearch}
                    onChange={(e) => {
                        if (e.target.value === '') {
                            setShowDropdown(false);
                        }
                        Number(e.target.value) > 2 ?? handleSearch(e.target.value)

                    }}
                    className="w-96"
                    size="large"
                />
            </div>
            {showDropdown && (
                <div className="absolute z-10 bg-white shadow-lg rounded-md w-96 max-h-96 overflow-y-auto" style={{ left: '50%', transform: 'translateX(-50%)' }}>
                    {loading ? (
                        <div className="flex justify-center items-center h-24">
                            <Spin size="large" />
                        </div>
                    ) : (
                        renderResults()
                    )}
                </div>
            )}
        </div>
    );
};

export default GeneralSearch;