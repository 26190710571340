import {
    ACTIVE_COMPANY, BUSINESS_IDENTITY, BUSINESS_INFORMATION,
    CLOSE_COMPANY_DRAWER, CONTACT_PERSONS,
    OPEN_COMPANY_DRAWER, RESET_COMPANY,
} from '../../constants/ActionTypes'


const INIT_STATE = {
    companyDrawer: false,
    activeCompany: null,
    businessInformation: null,
    contactPersons: null,
    businessIdentity: null,
    defaultCompany: null,

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case BUSINESS_INFORMATION: {
            return {
                ...state,
                businessInformation: action.payload,
            }
        }

        case CONTACT_PERSONS: {
            return {
                ...state,
                contactPersons: action.payload,
            }
        }
        case BUSINESS_IDENTITY: {
            return {
                ...state,
                businessIdentity: action.payload,
            }
        }
        case RESET_COMPANY: {
            return {
                ...state,
                businessInformation: null,
                businessIdentity: null,
                contactPersons: null,

            }
        }

        case OPEN_COMPANY_DRAWER:
            return {
                ...state,
                companyDrawer: true,

            }

        case CLOSE_COMPANY_DRAWER:
            return {
                ...state,
                companyDrawer: false,

            }

        case ACTIVE_COMPANY: {
            return {
                ...state,
                activeCompany: action.payload,
            }
        }




        default:
            return state;
    }
}
